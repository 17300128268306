<script setup>
import { h, onUpdated, onMounted, ref} from 'vue';

const props = defineProps({
    col: Object,
    colIndex: Number,
    activeEditCell: null,
    row: Object,
    showFull: {
      type: Boolean,
      default: false
    }
});

const nodeRef = ref(null);

function OCardCellRenderer(props) {
    return h(props.column.cellRenderSlot, {
        row: props.modelValue,
        'onUpdate:row': (value) => context.emit('update:modelValue', value),
        column: props.column,
        editModeOn: props.editModeOn,
    });
};
OCardCellRenderer.props = ['modelValue', 'column', 'editModeOn'];
OCardCellRenderer.emits = ['update:modelValue'];

</script>

<script>
import { OLink } from 'o365-ui-components';


function grabTextNodes(elem) {
  const walker = document.createTreeWalker(elem, NodeFilter.SHOW_TEXT, null);
  const nodes = [];
  while (walker.nextNode()) {
    nodes.push(walker.currentNode);
  }
  return nodes;
}

export default {
    name: 'ODataGridBodyCell',
    components: {OLink}
}
    
</script>

<template>
    <div class="o365-body-cell" tabindex="-1" :data-o365-colindex="colIndex" :o365-field="col.field"
        :class="col.cellClass">
            <template v-if="col.headerTitle">
                <label class="cell-body-title text-muted">{{col.headerTitle}}</label>
            </template>

             <span ref="nodeRef" class="text-limit-lines" :class="{'break-word': !showFull && col.lines == 1}" :style="!showFull && col.lines > 0 ? {'-webkit-line-clamp': col.lines} : ''">
              <o-card-cell-renderer
                  v-if="col.cellRenderSlot"
                  :column="col"
                  v-model="row.item">
              </o-card-cell-renderer>

              <template v-else-if="col.cellRenderer">
                  <template v-if="col.cellRendererIsFunction">
                      <div v-html="col.cellRenderer(row)"></div>
                  </template>
                  <component v-else :is="col.cellRenderer" 
                      v-model="row.item" 
                      :column="col"
                      :editModeOn="activeEditCell === colIndex+'-'+row.item._key"
                      :instance="$root.$data">
                  </component>
              </template>

              <template v-else>{{$format(row.item[col.field] ?? col.fallbackValue, col)}}</template>
            </span>
    </div>
</template>

<style>
.break-word {
    word-break: break-all;
}

.text-limit-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
  overflow:hidden;
}

.cell-body-title {
  display: block;
}
</style>


